<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Title</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Main Title
        <a class="close" v-on:click="collapsed['main'] = !collapsed['main']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['main']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="TitleMainDisplay" type="radio" name="TitleMainDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <input v-model="TitleMainText" type="text" class="type100" placeholder="Spool Installation" />
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="TitleMainX" type="text" class="type_double" />
          <input v-model="TitleMainY" type="text" />
        </div>
        <div>
          <div class="type_common_select">
            <label>Font</label>
            <select v-model="TitleMainFont" dir="rtl">
              <option
                v-for="option in fontOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_select">
            <label>Style</label>
            <select v-model="TitleMainStyle" dir="rtl" class="type70">
              <option
                v-for="option in fontStyleOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_input">
            <label>Size</label>
            <input v-model="TitleMainSize" type="number" />
          </div>
          <div class="type_common_input">
            <j-color-picker v-model="TitleMainColor" title="Color"></j-color-picker>
          </div>
          <div class="type_common_input column align_end">
            <span class="label alignself_start">Alignment</span>
            <div class="row">
              <label v-for="option in alignHOptions" :key="option.index" class="type_many">
                <input
                  v-model="TitleMainAlign"
                  type="radio"
                  name="TitleMainAlign"
                  :value="option.value"
                />
                {{option.text}}
              </label>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Sub Title
        <a class="close" v-on:click="collapsed['sub'] = !collapsed['sub']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['sub']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="TitleSubDisplay" type="radio" name="TitleSubDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <input v-model="TitleSubText" type="text" class="type100" placeholder="Spool Installation" />
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="TitleSubX" type="text" class="type_double" />
          <input v-model="TitleSubY" type="text" />
        </div>
        <div>
          <div class="type_common_select">
            <label>Font</label>
            <select v-model="TitleSubFont" dir="rtl">
              <option
                v-for="option in fontOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_select">
            <label>Style</label>
            <select v-model="TitleSubStyle" dir="rtl" class="type70">
              <option
                v-for="option in fontStyleOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_input">
            <label>Size</label>
            <input v-model="TitleSubSize" type="number" />
          </div>
          <div class="type_common_input">
            <j-color-picker v-model="TitleSubColor" title="Color"></j-color-picker>
          </div>
          <div class="type_common_input column align_end">
            <span class="label alignself_start">Alignment</span>
            <div class="row">
              <label v-for="option in alignHOptions" :key="option.index" class="type_many">
                <input
                  v-model="TitleSubAlign"
                  type="radio"
                  name="TitleSubAlign"
                  :value="option.value"
                />
                {{option.text}}
              </label>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Append Title
        <a class="close" v-on:click="collapsed['append'] = !collapsed['append']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['append']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="TitleExtraDisplay" type="radio" name="TitleExtraDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <input
          v-model="TitleExtraText"
          type="text"
          class="type100"
          placeholder="Spool Installation"
        />
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="TitleExtraX" type="text" class="type_double" />
          <input v-model="TitleExtraY" type="text" />
        </div>
        <div>
          <div class="type_common_select">
            <label>Font</label>
            <select v-model="TitleExtraFont" dir="rtl">
              <option
                v-for="option in fontOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_select">
            <label>Style</label>
            <select v-model="TitleExtraStyle" dir="rtl" class="type70">
              <option
                v-for="option in fontStyleOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_input">
            <label>Size</label>
            <input v-model="TitleExtraSize" type="number" />
          </div>
          <div class="type_common_input">
            <j-color-picker v-model="TitleExtraColor" title="Color"></j-color-picker>
          </div>
          <div class="type_common_input column align_end">
            <span class="label alignself_start">Alignment</span>
            <div class="row">
              <label v-for="option in alignHOptions" :key="option.index" class="type_many">
                <input
                  v-model="TitleExtraAlign"
                  type="radio"
                  name="TitleExtraAlign"
                  :value="option.value"
                />
                {{option.text}}
              </label>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        SQL Title
        <a class="close" v-on:click="collapsed['sql'] = !collapsed['sql']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['sql']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="TitleSqlDisplay" type="radio" name="TitleSqlDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <span class="label">Query</span>
          <div class="row">
            <div justify="center" style="align-items: center">
              <v-btn @click.stop="exp['sql'] = true" class="type_openTextarea">Title from the sql result.</v-btn>
              <j-modal-scripter 
                v-model="TitleSqlQuery" 
                sub-title="Access Database"
                title="Title SQL Query"
                :message="validated.TitleSqlQuery.message"
                :opened="exp.sql"
                :valid="validated.TitleSqlQuery.valid"
                @close="exp.sql=false;"
                @save="exp.sql=false;onSave();"
                @validate="onValidate('TitleSqlQuery')"
              />
            </div>
          </div>
        </div>
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="TitleSqlX" type="text" class="type_double" />
          <input v-model="TitleSqlY" type="text" />
        </div>
        <div>
          <div class="type_common_select">
            <label>Font</label>
            <select v-model="TitleSqlFont" dir="rtl">
              <option
                v-for="option in fontOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_select">
            <label>Style</label>
            <select v-model="TitleSqlStyle" dir="rtl">
              <option
                v-for="option in fontStyleOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_input">
            <label>Size</label>
            <input v-model="TitleSqlSize" type="number" />
          </div>
          <div class="type_common_input">
            <j-color-picker v-model="TitleSqlColor" title="Color"></j-color-picker>
          </div>
          <div class="type_common_input column align_end">
            <span class="label alignself_start">Alignment</span>
            <div class="row">
              <label v-for="option in alignHOptions" :key="option.index" class="type_many">
                <input
                  v-model="TitleSqlAlign"
                  type="radio"
                  name="TitleSqlAlign"
                  :value="option.value"
                />
                {{option.text}}
              </label>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div v-if="useAxisTitle" class="chart_properties_contents">
      <div class="header">
        Axis Primary Title
        <a class="close" v-on:click="collapsed['pr'] = !collapsed['pr']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['pr']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="TitlePrDisplay" type="radio" name="TitlePrDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <input
          v-model="TitlePrText"
          type="text"
          class="type100"
          placeholder="Spool Installation"
        />
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="TitlePrX" type="text" class="type_double" />
          <input v-model="TitlePrY" type="text" />
        </div>
        <div>
          <div class="type_common_select">
            <label>Font</label>
            <select v-model="TitlePrFont" dir="rtl">
              <option
                v-for="option in fontOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_select">
            <label>Style</label>
            <select v-model="TitlePrStyle" dir="rtl" class="type70">
              <option
                v-for="option in fontStyleOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_input">
            <label>Size</label>
            <input v-model="TitlePrSize" type="number" />
          </div>
          <div class="type_common_input">
            <j-color-picker v-model="TitlePrColor" title="Color"></j-color-picker>
          </div>
          <div class="type_common_input column align_end">
            <span class="label alignself_start">Alignment</span>
            <div class="row">
              <label v-for="option in alignHOptions" :key="option.index" class="type_many">
                <input
                  v-model="TitlePrAlign"
                  type="radio"
                  name="TitlePrAlign"
                  :value="option.value"
                />
                {{option.text}}
              </label>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div v-if="useAxisTitle" class="chart_properties_contents">
      <div class="header">
        Axis Secondary Title
        <a class="close" v-on:click="collapsed['se'] = !collapsed['se']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['se']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="TitleSeDisplay" type="radio" name="TitleSeDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <input
          v-model="TitleSeText"
          type="text"
          class="type100"
          placeholder="Spool Installation"
        />
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="TitleSeX" type="text" class="type_double" />
          <input v-model="TitleSeY" type="text" />
        </div>
        <div>
          <div class="type_common_select">
            <label>Font</label>
            <select v-model="TitleSeFont" dir="rtl">
              <option
                v-for="option in fontOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_select">
            <label>Style</label>
            <select v-model="TitleSeStyle" dir="rtl" class="type70">
              <option
                v-for="option in fontStyleOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_input">
            <label>Size</label>
            <input v-model="TitleSeSize" type="number" />
          </div>
          <div class="type_common_input">
            <j-color-picker v-model="TitleSeColor" title="Color"></j-color-picker>
          </div>
          <div class="type_common_input column align_end">
            <span class="label alignself_start">Alignment</span>
            <div class="row">
              <label v-for="option in alignHOptions" :key="option.index" class="type_many">
                <input
                  v-model="TitleSeAlign"
                  type="radio"
                  name="TitleSeAlign"
                  :value="option.value"
                />
                {{option.text}}
              </label>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Grid Line
        <a class="close" v-on:click="collapsed['grid'] = !collapsed['grid']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['grid']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="TitleLineDisplay" type="radio" name="TitleLineDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="TitleLineX" type="text" class="type_double" />
          <input v-model="TitleLineY" type="text" />
        </div>
        <div class="type_common_input">
          <label>Length</label>
          <input v-model="TitleLineLength" type="number" />
        </div>
        <div class="type_common_input">
          <label>Weight</label>
          <input v-model="TitleLineWeight" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="TitleLineColor" title="Color" />
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { ChartLibraryService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-title',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  props: {
    useAxisTitle: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    chartLibraryService: null,

    collapsed: {
      main: true,
      sub: false,
      append: false,
      sql: false,
      pr: false,
      se: false,
      grid: false
    },
    exp: {
      sql: false,
    },
    validated: {
      TitleSqlQuery: { valid: true, message: '' },
    },
    bar3d_general: null,
    previewMode: [
      { text: "Chart Preview", val: "chart" },
      { text: "Data Table", val: "table" }
    ],
    dataImport: [
      { text: 'option01', val: 'option01' },
      { text: 'option02', val: 'option02' },
      { text: 'option03', val: 'option03' }
    ],
    chartReference: '',
    exp_textarea: false,

    DatabasePreview: '',
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['title']),

    TitleMainDisplay: {
      get() { return this.title.TitleMainDisplay },
      set(val) { this.setChartItem({ TitleMainDisplay: val }) }
    },
    TitleMainText: {
      get() { return this.title.TitleMainText },
      set(val) { this.setChartItem({ TitleMainText: val }) }
    },
    TitleMainX: {
      get() { return this.title.TitleMainX || 0 },
      set(val) { this.setChartItem({ TitleMainX: parseFloat(val || 0) }) }
    },
    TitleMainY: {
      get() { return this.title.TitleMainY || 0 },
      set(val) { this.setChartItem({ TitleMainY: parseFloat(val || 0) }) }
    },
    TitleMainFont: {
      get() { return this.title.TitleMainFont },
      set(val) { this.setChartItem({ TitleMainFont: val }) }
    },
    TitleMainStyle: {
      get() { return this.title.TitleMainStyle },
      set(val) { this.setChartItem({ TitleMainStyle: val }) }
    },
    TitleMainSize: {
      get() { return this.title.TitleMainSize || 0 },
      set(val) { this.setChartItem({ TitleMainSize: parseFloat(val || 0) }) }
    },
    TitleMainColor: {
      get() { return this.title.TitleMainColor },
      set(val) { this.setChartItem({ TitleMainColor: val }) }
    },
    TitleMainAlign: {
      get() { return this.title.TitleMainAlign },
      set(val) { this.setChartItem({ TitleMainAlign: val }) }
    },
    TitleSubDisplay: {
      get() { return this.title.TitleSubDisplay },
      set(val) { this.setChartItem({ TitleSubDisplay: val }) }
    },
    TitleSubText: {
      get() { return this.title.TitleSubText },
      set(val) { this.setChartItem({ TitleSubText: val }) }
    },
    TitleSubX: {
      get() { return this.title.TitleSubX || 0 },
      set(val) { this.setChartItem({ TitleSubX: parseFloat(val || 0) }) }
    },
    TitleSubY: {
      get() { return this.title.TitleSubY || 0 },
      set(val) { this.setChartItem({ TitleSubY: parseFloat(val || 0) }) }
    },
    TitleSubFont: {
      get() { return this.title.TitleSubFont },
      set(val) { this.setChartItem({ TitleSubFont: val }) }
    },
    TitleSubStyle: {
      get() { return this.title.TitleSubStyle },
      set(val) { this.setChartItem({ TitleSubStyle: val }) }
    },
    TitleSubSize: {
      get() { return this.title.TitleSubSize || 0 },
      set(val) { this.setChartItem({ TitleSubSize: parseFloat(val || 0) }) }
    },
    TitleSubColor: {
      get() { return this.title.TitleSubColor },
      set(val) { this.setChartItem({ TitleSubColor: val }) }
    },
    TitleSubAlign: {
      get() { return this.title.TitleSubAlign },
      set(val) { this.setChartItem({ TitleSubAlign: val }) }
    },
    TitleExtraDisplay: {
      get() { return this.title.TitleExtraDisplay },
      set(val) { this.setChartItem({ TitleExtraDisplay: val }) }
    },
    TitleExtraText: {
      get() { return this.title.TitleExtraText },
      set(val) { this.setChartItem({ TitleExtraText: val }) }
    },
    TitleExtraX: {
      get() { return this.title.TitleExtraX || 0 },
      set(val) { this.setChartItem({ TitleExtraX: parseFloat(val || 0) }) }
    },
    TitleExtraY: {
      get() { return this.title.TitleExtraY || 0 },
      set(val) { this.setChartItem({ TitleExtraY: parseFloat(val || 0) }) }
    },
    TitleExtraFont: {
      get() { return this.title.TitleExtraFont },
      set(val) { this.setChartItem({ TitleExtraFont: val }) }
    },
    TitleExtraStyle: {
      get() { return this.title.TitleExtraStyle },
      set(val) { this.setChartItem({ TitleExtraStyle: val }) }
    },
    TitleExtraSize: {
      get() { return this.title.TitleExtraSize || 0 },
      set(val) { this.setChartItem({ TitleExtraSize: parseFloat(val || 0) }) }
    },
    TitleExtraColor: {
      get() { return this.title.TitleExtraColor },
      set(val) { this.setChartItem({ TitleExtraColor: val }) }
    },
    TitleExtraAlign: {
      get() { return this.title.TitleExtraAlign },
      set(val) { this.setChartItem({ TitleExtraAlign: val }) }
    },
    TitleSqlDisplay: {
      get() { return this.title.TitleSqlDisplay },
      set(val) { this.setChartItem({ TitleSqlDisplay: val }) }
    },
    TitleSqlText: {
      get() { return this.title.TitleSqlText },
      set(val) { this.setChartItem({ TitleSqlText: val }) }
    },
    TitleSqlQuery: {
      get() { return this.title.TitleSqlQuery },
      set(val) { this.setChartItem({ TitleSqlQuery: val }) }
    },
    TitleSqlX: {
      get() { return this.title.TitleSqlX || 0 },
      set(val) { this.setChartItem({ TitleSqlX: parseFloat(val || 0) }) }
    },
    TitleSqlY: {
      get() { return this.title.TitleSqlY || 0 },
      set(val) { this.setChartItem({ TitleSqlY: parseFloat(val || 0) }) }
    },
    TitleSqlFont: {
      get() { return this.title.TitleSqlFont },
      set(val) { this.setChartItem({ TitleSqlFont: val }) }
    },
    TitleSqlStyle: {
      get() { return this.title.TitleSqlStyle },
      set(val) { this.setChartItem({ TitleSqlStyle: val }) }
    },
    TitleSqlSize: {
      get() { return this.title.TitleSqlSize || 0 },
      set(val) { this.setChartItem({ TitleSqlSize: parseFloat(val || 0) }) }
    },
    TitleSqlColor: {
      get() { return this.title.TitleSqlColor },
      set(val) { this.setChartItem({ TitleSqlColor: val }) }
    },
    TitleSqlAlign: {
      get() { return this.title.TitleSqlAlign },
      set(val) { this.setChartItem({ TitleSqlAlign: val }) }
    },
    TitleLineDisplay: {
      get() { return this.title.TitleLineDisplay },
      set(val) { this.setChartItem({ TitleLineDisplay: val }) }
    },
    TitleLineX: {
      get() { return this.title.TitleLineX || 0 },
      set(val) { this.setChartItem({ TitleLineX: parseFloat(val || 0) }) }
    },
    TitleLineY: {
      get() { return this.title.TitleLineY || 0 },
      set(val) { this.setChartItem({ TitleLineY: parseFloat(val || 0) }) }
    },
    TitleLineLength: {
      get() { return this.title.TitleLineLength || 0 },
      set(val) { this.setChartItem({ TitleLineLength: parseFloat(val || 0) }) }
    },
    TitleLineWeight: {
      get() { return this.title.TitleLineWeight || 0 },
      set(val) { this.setChartItem({ TitleLineWeight: parseFloat(val || 0) }) }
    },
    TitleLineColor: {
      get() { return this.title.TitleLineColor },
      set(val) { this.setChartItem({ TitleLineColor: val }) }
    },

    // Double Axis Titles
    TitlePrDisplay: {
      get() { return this.title.TitlePrDisplay },
      set(val) { this.setChartItem({ TitlePrDisplay: val })}
    },
    TitlePrText: {
      get() { return this.title.TitlePrText },
      set(val) { this.setChartItem({ TitlePrText: val })}
    },
    TitlePrX: {
      get() { return this.title.TitlePrX || 0 },
      set(val) { this.setChartItem({ TitlePrX: parseFloat(val || 0) })}
    },
    TitlePrY: {
      get() { return this.title.TitlePrY || 0 },
      set(val) { this.setChartItem({ TitlePrY: parseFloat(val || 0) })}
    },
    TitlePrFont: {
      get() { return this.title.TitlePrFont },
      set(val) { this.setChartItem({ TitlePrFont: val })}
    },
    TitlePrStyle: {
      get() { return this.title.TitlePrStyle },
      set(val) { this.setChartItem({ TitlePrStyle: val })}
    },
    TitlePrSize: {
      get() { return this.title.TitlePrSize || 0 },
      set(val) { this.setChartItem({ TitlePrSize: parseFloat(val || 0) })}
    },
    TitlePrColor: {
      get() { return this.title.TitlePrColor },
      set(val) { this.setChartItem({ TitlePrColor: val })}
    },
    TitlePrAlign: {
      get() { return this.title.TitlePrAlign },
      set(val) { this.setChartItem({ TitlePrAlign: val })}
    },
    TitleSeDisplay: {
      get() { return this.title.TitleSeDisplay },
      set(val) { this.setChartItem({ TitleSeDisplay: val })}
    },
    TitleSeText: {
      get() { return this.title.TitleSeText },
      set(val) { this.setChartItem({ TitleSeText: val })}
    },
    TitleSeX: {
      get() { return this.title.TitleSeX || 0 },
      set(val) { this.setChartItem({ TitleSeX: parseFloat(val || 0) })}
    },
    TitleSeY: {
      get() { return this.title.TitleSeY || 0 },
      set(val) { this.setChartItem({ TitleSeY: parseFloat(val || 0) })}
    },
    TitleSeFont: {
      get() { return this.title.TitleSeFont },
      set(val) { this.setChartItem({ TitleSeFont: val })}
    },
    TitleSeStyle: {
      get() { return this.title.TitleSeStyle },
      set(val) { this.setChartItem({ TitleSeStyle: val })}
    },
    TitleSeSize: {
      get() { return this.title.TitleSeSize || 0 },
      set(val) { this.setChartItem({ TitleSeSize: parseFloat(val || 0) })}
    },
    TitleSeColor: {
      get() { return this.title.TitleSeColor },
      set(val) { this.setChartItem({ TitleSeColor: val })}
    },
    TitleSeAlign: {
      get() { return this.title.TitleSeAlign },
      set(val) { this.setChartItem({ TitleSeAlign: val })}
    },

  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    onSave() {
      this.$emit('save')
    },
    onValidate(typeName) {
      this.validateSql(this[typeName]).then(res => {
        this.validated[typeName] = res
      })
    }
  }
}
</script>
