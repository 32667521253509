<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Canvas</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Canvas
        <a class="close" v-on:click="collapsed['canvas'] = !collapsed['canvas']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['canvas']">
        <div class="type_common_input">
          <label>Width</label>
          <input v-model="CanvasWidth" type="text" />
        </div>
        <div class="type_common_input">
          <label>Height</label>
          <input v-model="CanvasHeight" type="text" />
        </div>
        <div class="type_common_input">
          <label>Border Weight</label>
          <input v-model="CanvasBorderWeight" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="CanvasBorderColor" title="Border Color" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="CanvasFillColor" title="Fill Color" />
        </div>
        <div class="type_common_input">
          <label>Opacity</label>
          <input v-model="CanvasOpacity" type="number" />
        </div>
      </v-flex>
    </div>
    <div v-if="chart" class="chart_properties_contents">
      <div class="header">
        Chart
        <small class="header_ref">(Critical Info.)</small>
        <a class="close type_critical" v-on:click="collapsed['chart'] = !collapsed['chart']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['chart']">
        <div class="type_common_input">
          <label>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="CanvasChartX" type="text" class="type_mgl0 type_double" />
          <input v-model="CanvasChartY" type="text" />
        </div>
        <div class="type_common_input">
          <label>Width</label>
          <input v-model="CanvasChartWidth" type="text" />
        </div>
        <div class="type_common_input">
          <label>Height</label>
          <input v-model="CanvasChartHeight" type="text" />
        </div>
        <div class="type_common_input">
          <label>Border Weight</label>
          <input v-model="CanvasChartBorderWeight" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="CanvasChartBorderColor" title="Border Color" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="CanvasChartFillColor" title="Fill Color" />
        </div>
        <div class="type_common_input">
          <label>Opacity</label>
          <input v-model="CanvasChartOpacity" type="number" />
        </div>
      </v-flex>
      <div v-if="chartSpace">
        <div class="header">Chart Space</div>
        <v-flex class="control">
          <div class="type_common_input">
            <label>
              Space
              <small class="header_ref">(from Border)</small>
            </label>
            <input v-model="CanvasChartSpace" type="number" />
          </div>
        </v-flex>
      </div>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Font-family
        <a class="close" v-on:click="collapsed['font'] = !collapsed['font']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['font']">
        <div class="type_common_select">
          <label>Default</label>
          <select v-model="CanvasFontFamily" @change="onChangeCanvasFontFamily" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-canvas',
  mixins: [
    StaticOptions
  ],
  props: {
    chart: { type: Boolean, default: true },
    chartSpace: { type: Boolean, default: true }
  },
  data: () => ({
    bar3d_general: null,
    collapsed: {
      canvas: true,
      chart: false,
      font: false
    },
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['canvas']),

    CanvasWidth: {
      get() { return this.canvas.CanvasWidth || 0 },
      set(val) { this.setChartItem({ CanvasWidth: parseFloat(val || 0) }) }
    },
    CanvasHeight: {
      get() { return this.canvas.CanvasHeight || 0 },
      set(val) { this.setChartItem({ CanvasHeight: parseFloat(val || 0) }) }
    },
    CanvasBorderWeight: {
      get() { return this.canvas.CanvasBorderWeight || 0 },
      set(val) { this.setChartItem({ CanvasBorderWeight: parseFloat(val || 0) }) }
    },
    CanvasBorderColor: {
      get() { return this.canvas.CanvasBorderColor },
      set(val) { this.setChartItem({ CanvasBorderColor: val }) }
    },
    CanvasFillColor: {
      get() { return this.canvas.CanvasFillColor },
      set(val) { this.setChartItem({ CanvasFillColor: val }) }
    },
    CanvasOpacity: {
      get() { return this.canvas.CanvasOpacity || 0 },
      set(val) { this.setChartItem({ CanvasOpacity: parseFloat(val || 0) }) }
    },
    CanvasChartAreaAllowed: {
      get() { return this.canvas.CanvasChartAreaAllowed },
      set(val) { this.setChartItem({ CanvasChartAreaAllowed: val }) }
    },
    CanvasChartX: {
      get() { return this.canvas.CanvasChartX || 0 },
      set(val) { this.setChartItem({ CanvasChartX: parseFloat(val || 0) }) }
    },
    CanvasChartY: {
      get() { return this.canvas.CanvasChartY || 0 },
      set(val) { this.setChartItem({ CanvasChartY: parseFloat(val || 0) }) }
    },
    CanvasChartWidth: {
      get() { return this.canvas.CanvasChartWidth || 0 },
      set(val) { this.setChartItem({ CanvasChartWidth: parseFloat(val || 0) }) }
    },
    CanvasChartHeight: {
      get() { return this.canvas.CanvasChartHeight || 0 },
      set(val) { this.setChartItem({ CanvasChartHeight: parseFloat(val || 0) }) }
    },
    CanvasChartBorderWeight: {
      get() { return this.canvas.CanvasChartBorderWeight || 0 },
      set(val) { this.setChartItem({ CanvasChartBorderWeight: parseFloat(val || 0) }) }
    },
    CanvasChartBorderColor: {
      get() { return this.canvas.CanvasChartBorderColor },
      set(val) { this.setChartItem({ CanvasChartBorderColor: val }) }
    },
    CanvasChartFillColor: {
      get() { return this.canvas.CanvasChartFillColor },
      set(val) { this.setChartItem({ CanvasChartFillColor: val }) }
    },
    CanvasChartOpacity: {
      get() { return this.canvas.CanvasChartOpacity || 0 },
      set(val) { this.setChartItem({ CanvasChartOpacity: parseFloat(val || 0) }) }
    },
    CanvasChartSpace: {
      get() { return this.canvas.CanvasChartSpace || 0 },
      set(val) { this.setChartItem({ CanvasChartSpace: parseFloat(val || 0) }) }
    },
    CanvasFontFamily: {
      get() { return this.canvas.CanvasFontFamily },
      set(val) { this.setChartItem({ CanvasFontFamily: val }) }
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    onChangeCanvasFontFamily() {
      if (Object.keys(this.chartItem).length > 0) {
        let obj_ = {}
        Object.keys(this.chartItem).map(key_ => { if (key_.includes('Font')) (obj_[key_] = this.CanvasFontFamily) })
        this.setChartItem(obj_)
      }
    }
  }
}
</script>
