<template>
  <v-flex v-model="bar3d_general" class="chart_properties dark_theme" wrap>
    <div class="chart_properties_header">
      <span>Navigation</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents type_noborder">
      <div class="header">Name</div>
      <v-flex class="control">
        <input v-model="Name" type="text" placeholder="Chart Name" class="type100" />
      </v-flex>
    </div>
    <div v-if="defineNav" class="chart_properties_contents">
      <div class="header">Navigation</div>
      <v-flex class="control">
        <div>
          <div class="type_inner type_tree">
            <v-select
              v-model="PhaseCode"
              class="tree_node"
              item-text="phaseName"
              item-value="phaseCode"
              label="Phase"
              placeholder="- Required"
              required
              attach
              :items="phaseOptions"
              :menu-props="{contentClass:'single_select'}"
              :rules="[v => !!v || 'Item is required']"
              @input="onPhaseChanged"
            ></v-select>
            <v-select
              v-model="GroupCode"
              class="tree_node"
              item-text="groupName"
              item-value="groupCode"
              label="Menu Group"
              placeholder="- Required"
              required
              attach
              :items="groupOptions"
              :menu-props="{contentClass:'single_select'}"
              :rules="[v => !!v || 'Item is required']"
              @input="onGroupChanged"
            ></v-select>
            <v-select
              v-model="ItemCode"
              class="tree_node"
              item-text="itemName"
              item-value="itemCode"
              label="Menu Item"
              placeholder="- Required"
              required
              attach
              :items="itemOptions"
              :menu-props="{contentClass:'single_select'}"
              :rules="[v => !!v || 'Item is required']"
              @input="onItemChanged"
            ></v-select>
            <v-select
              v-model="SubItem"
              attach
              class="tree_end"
              item-text="subItemName"
              item-value="idx"
              label="Sub Item"
              placeholder="- Optional"
              :items="subItemOptions"
              :menu-props="{contentClass:'single_select'}"
            ></v-select>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">Import Property Data</div>
      <v-flex class="control">
        <div class="column align_end">
          <v-select
            v-model="refcode"
            style="width:20rem;"            
            item-text="name"
            item-value="code"
            attach
            :items="importOptions"
            :menu-props="{contentClass:'single_select'}"
          ></v-select>
          <!-- <select v-model="refcode" class="type100">
            <option v-for="item in importOptions" :key="item.index" :value="item.code">{{item.name}}</option>
          </select> -->
          <a class="type01" @click="importItem">Import Data</a>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header" style="margin-bottom: 1rem;">
        <div class="row">
          Tags
          <div class="input_flex_wrap code_title_wrapper" 
            style="padding-left: 1rem; font-size: 90%;">
            <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendTags()">
              <label>Add</label>
            </div>
          </div>
        </div>
        <a class="close" @click="collapsed['tags'] = !collapsed['tags']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['tags']">
        <div v-for="(tags, i) in localTags" class="content_list"  :key="tags.name">
          <div data-grid-quickbtn="btn" class="jcon_temp_minus type_header_before"  @click="removeTags(i)" />
          <div class="row" >
            <div class="type_common_input" style="margin-top: -4px;">
              <label>Tag.{{ i + 1 }}</label>
              <input v-model="tags.script" type="text" class="type147" @input="updateTags" />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Usage Status
        <a class="close" v-on:click="collapsed['usage'] = !collapsed['usage']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['usage']">
        <div class="type_common_input">
          <span class="label">In Use</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="UsageStatus" type="radio" name="UsageStatus" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from "vuex"
import { ChartLibraryService, SystemCodeService, SystemService } from "@/services"
import StaticOptions from '@/mixins/staticOptions'
import { QueryValidationMixin } from "@/mixins/sqlValidator"

export default {
  name: 'j-chart-tab-navigation',
  mixins: [
    QueryValidationMixin,StaticOptions
  ],
  props: {
    formMode: String,
    defineNav: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    // Service Instances -------------
    chartLibraryService: null,
    systemCodeService: null,
    systemService: null,
    // options -----------------------
    phaseOptions: [],
    groupOptions: [],
    itemOptions: [],
    subItemOptions: [],
    filterOptions: [
      { text: "Yes", value: "Y" },
      { text: "No", value: "N" }
    ],
    dbtypeOptions: [
      { text: "Query", value: "Q" },
      { text: "Store Procedure", value: "P" }
    ],
    // Tags
    collapsed: {
      tags: true,
      usage: true,
    },    
    // localTags: {
    //   Default: [{name: 'TAG1',   script: ''}],
    //   RealData:[],
    // },
    localTags:[],
    // rules -------------------------
    codeRules: [v => (!!v && v != 'ALL') || "Required"],
    defaultRules: [v => !!v || "Required"],
    // Other component owned ---------
    validated: {
      QSummary: { valid: true, message: '' },
    },

    importOptions: [],
    refcode: '',

    // temporal data
    bar3d_general: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(value) { this.$store.commit("sysenv/codePropagate", value) }
    },

    PhaseCode: {
      get() { return this.chartItem.PhaseCode },
      set(val) { this.setChartItem({ PhaseCode: val }) }
    },
    GroupCode: {
      get() { return this.chartItem.GroupCode },
      set(val) { this.setChartItem({ GroupCode: val }) }
    },
    ItemCode: {
      get() { return this.chartItem.ItemCode },
      set(val) { this.setChartItem({ ItemCode: val }) }
    },
    SubItem: {
      get() { return this.chartItem.SubItem },
      set(val) { this.setChartItem({ SubItem: val }) }
    },
    Name: {
      get() { return this.chartItem.Name },
      set(val) { this.setChartItem({ Name: val }) }
    },
    Filtering: {
      get() { return this.chartItem.Filtering },
      set(val) { this.setChartItem({ Filtering: val }) }
    },
    CatCode: {
      get() { return this.chartItem.CatCode },
      set(val) { this.setChartItem({ CatCode: val }) }
    },
    ChartType: {
      get() { return this.chartItem.ChartType },
      set(val) { this.setChartItem({ ChartType: val }) }
    },
    ChartDb: {
      get() { return this.chartItem.ChartDb },
      set(val) { this.setChartItem({ ChartDb: val }) }
    },
    ChartNo: {
      get() { return this.chartItem.ChartNo },
      set(val) { this.setChartItem({ ChartNo: val }) }
    },
    DbAccess: {
      get() { return this.chartItem.DbAccess },
      set(val) { this.setChartItem({ DbAccess: val }) }
    },
    QApplied: {
      get() { return this.chartItem.QApplied },
      set(val) { this.setChartItem({ QApplied: val }) }
    },
    QSummary: {
      get() { return this.chartItem.QSummary },
      set(val) { this.setChartItem({ QSummary: val }) }
    },
    QDatagrid: {
      get() { return this.chartItem.QDatagrid },
      set(val) { this.setChartItem({ QDatagrid: val }) }
    },
    QSp: {
      get() { return this.chartItem.QSp },
      set(val) { this.setChartItem({ QSp: val }) }
    },
    JsonString: {
      get() { return this.chartItem.JsonString },
      set(val) { this.setChartItem({ JsonString: val }) }
    },
    JsonProps: {
      get() { return this.chartItem.JsonProps },
      set(val) { this.setChartItem({ JsonProps: val }) }
    },
    Description: {
      get() { return this.chartItem.Description },
      set(val) { this.setChartItem({ Description: val }) }
    },
    Tags: {
      get() { return this.chartItem.Tags },
    },
    UsageStatus: {
      get() { return this.chartItem.UsageStatus },
      set(val) { this.setChartItem({ UsageStatus: val }) }
    },
  },
  watch: {
    ChartType(val) {
      if(!val) return
      this.getImportOptions()
    },    
    Tags: {
      handler(val) {
        if(!val) return
        this.localTags = JSON.parse(JSON.stringify(val))
      },
      deep: true,
    },
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
    this.systemCodeService = new SystemCodeService()
    this.systemService = new SystemService()
  },
  mounted() {
    this.systemService.getNavPhase(res => {
      if (!res) this.phaseOptions = []
      else this.phaseOptions = res
    })
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartData', 'setChartItem']),

    onPhaseChanged(phaseCode) {
      this.groupOptions = []
      if(phaseCode == 'ALL') return

      this.systemService.getNavGroup(phaseCode, res => {
        if (res) this.groupOptions = res
      })
    },
    onGroupChanged(groupCode) {
      this.itemOptions = []
      if(groupCode == 'ALL') return

      this.systemService.getNavItem(
        res => {
          if (res) this.itemOptions = res
        },
        this.PhaseCode,
        groupCode
      )
    },
    onItemChanged(itemCode) {
      this.subItemOptions = [
        {
          idx: 0,
          subItemName: 'None',
        }
      ]
      if (itemCode == "ALL") return
      if (!this.PhaseCode || !this.ItemCode) return

      this.systemService.getNavSubItem(
        res => {
          if (res) this.subItemOptions = [ ...this.subItemOptions, ...res ]
        },
        this.PhaseCode,
        this.ItemCode
      )
    },
    getImportOptions() {
      let catCode_ = this.chartItem.CatCode
      let chartType_ = this.chartItem.ChartType

      this.systemCodeService.chartOptions(catCode_, chartType_, (res) => {
        this.importOptions = res
      })
    },
    importItem() {
      if(!this.refcode) return
      this.$emit('import-item', this.refcode)
    },
    setData(mode) {
      if (mode == __C.FORM.EDIT_MODE_NEW) {
        this.PhaseCode = this.PhaseCode || this.codePropagated.parentCode
        this.GroupCode = this.GroupCode || this.codePropagated.groupCode
        this.ItemCode = this.ItemCode || this.codePropagated.code

        this.onPhaseChanged(this.PhaseCode)
        this.onGroupChanged(this.GroupCode)
        this.onItemChanged(this.ItemCode)

      } else {
        if(!this.refcode) this.refcode = this.chartItem.ChartNo

        this.onPhaseChanged(this.PhaseCode)
        this.onGroupChanged(this.GroupCode)
        this.onItemChanged(this.ItemCode)
      }

      this.getImportOptions()
    },
    appendTags() {
      let tags_ = JSON.parse(JSON.stringify(this.chartItem.Tags))
      let name_ = `TAG${tags_.length + 1}`
      if(tags_.length > 0){
        if(tags_.findIndex(t_ => t_.name == name_) >= 0) {
          console.error(`[USER: ] Duplicated tag name '${name_}'.`)
          return
        }
      }
      tags_.push({ name: name_, script: '' })
      this.setChartItem({ Tags: tags_ })
    },
    removeTags(i) {
      let tags_ = JSON.parse(JSON.stringify(this.chartItem.Tags))
      tags_[i] = null
      tags_ = tags_.filter(t_ => !!t_)

      this.setChartItem({ Tags: tags_ })
    },
    updateTags() {
      this.setChartItem({ Tags: this.localTags })
    },
  }
}
</script>
