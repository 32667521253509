<template>
  <div class="date_picker">
    <span class="icon_picker"><v-icon @click="menu = true">mdi-calendar</v-icon></span>
    <v-menu
      v-model="menu"
      content-class="date_picker_card"
      full-width
      nudge-top="14px"
      nudge-right="13px"
      offset-x
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template #activator="{ on }">
        <v-text-field
          v-on="on"
          v-model="dateFormatted"
          persistent-hint
          @blur="date= parseDate(dateFormatted)"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date" 
        no-title
        ref="datePicker"
        :color="pointColor"
        :events="cutOffDates"
        :event-color="eventColor"
        :reactive="true"
        @input="dateFormatted = formatDate($event)"
      />
    </v-menu>
  </div>
</template>

<script>
import '@/assets/stylus/ui/component/_jDatePicker.styl'
import Moment from 'moment'

export default {
  name: 'j-date-picker',
  props: {
    cutOffDay:  {type: String, default:'Fri'},
    pointColor: {type: String, default: '#40afe4'},
    type:       {type: String, default:'Normal'},
  },
  data: vm => ({
    cutOffDates: null,
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    eventColor: '#ddd',
    menu: false,
    weekMap: ['Sun','Mon','Tue','Wed','Tues','Fri','Sat']
  }),
  computed: {
    computedDateFormatted: {
      get() { return this.formatDate(this.date) },
      set(val) { this.date = val }
    },
  },
  watch: {
    date (val) {
      if(!val) return
      this.dateFormatted = this.formatDate(this.date)
      if(this.type == 'cutOff') {
        this.getCutOffDays(val)
        this.pickCutOffDay(val)
      }
    },
  },
  created() {
    if(this.type == 'cutOff') {
      this.getCutOffDays(this.date)
    }
  },
  mounted () {
    this.$watch(
      () => {
        return this.$refs.datePicker.tableDate
      },
      (val) => {
        if(this.type == 'cutOff') {
          this.getCutOffDays(val)
        }
      }
    )
  },
  methods: {
    getCutOffDays(picked) {
      let now = new Date(picked)
      let dayOfMonth = []

      for (let d = new Date(now.getFullYear(), now.getMonth() , 1); d < new Date(now.getFullYear(), now.getMonth()+1, 1); d.setDate(d.getDate() + 1)) {
        dayOfMonth.push(new Date(d))
      }

      this.cutOffDates = dayOfMonth
      .filter(d =>  new Date(d).getDay() === this.weekMap.indexOf(this.cutOffDay))
      .map(d => Moment(d).format('YYYY-MM-DD'))

      setTimeout(() => {
        this.setCutOffNodeStyl()
      }, 1)
    },
    setCutOffNodeStyl() {
      document.querySelectorAll('.v-date-picker-table__events div').forEach(el => {
        el.style.backgroundColor = 'transparent'
        el.parentNode.parentElement.getAttribute("style") ? el.style.borderColor = 'transparent' : el.style.borderColor = this.eventColor
      })
    },
    pickCutOffDay(val) {
      let interval = this.weekMap.indexOf(this.cutOffDay)
      let pickedCutOffDay

      if(new Date(val).getDay() > interval) {
        pickedCutOffDay = new Date(new Date(val).setDate(new Date(val).getDate() - new Date(val).getDay() + interval + this.weekMap.length));
      } else {
        pickedCutOffDay = new Date(new Date(val).setDate(new Date(val).getDate() - new Date(val).getDay() + interval));
      }

      this.date = pickedCutOffDay.toISOString().substr(0, 10)
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },
    parseDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>