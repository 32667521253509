<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Note</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Note
        <a class="close" v-on:click="collapsed['note'] = !collapsed['note']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['note']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="NoteDisplay" type="radio" name="NoteDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="NoteX" type="text" class="type_double" />
          <input v-model="NoteY" type="text" />
        </div>
        <div class="type_common_input column align_end">
          <span class="label alignself_start">Alignment</span>
          <div class="row">
            <label v-for="option in alignHOptions" :key="option.index" class="type_many">
              <input v-model="NoteAlign" type="radio" name="NoteAlign" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Text
        <a class="close" v-on:click="collapsed['text'] = !collapsed['text']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['text']">
        <div justify="center" class="pd_10">
          <v-btn
            @click.stop="exp_textarea01 = true"
            class="type_openTextarea type100"
          >Note Title ...</v-btn>
          <v-dialog v-model="exp_textarea01" persistent max-width="400" content-class="j_modal type01">
            <v-card>
              <v-card-title class="modal__header">
                <span>
                  Note Text
                  <small></small>
                </span>
                <j-button class="modal_close" @click.stop="exp_textarea01 = false">
                  <v-icon>mdi-close</v-icon>
                </j-button>
              </v-card-title>
              <v-card-text>
                <textarea v-model="NoteTitleText" class="type_exp" placeholder="Spool Installation"></textarea>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <j-button class="type01 sky" text @click="exp_textarea01 = false">Save</j-button>
                <j-button class="type01" text @click="exp_textarea01 = false">Cancel</j-button>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="NoteTitleFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="NoteTitleStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="NoteTitleSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="NoteTitleColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Contents
        <a class="close" v-on:click="collapsed['contents'] = !collapsed['contents']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['contents']">
        <div justify="center" class="pd_10">
          <v-btn
            @click.stop="exp_textarea02 = true"
            class="type_openTextarea type100"
          >Note Contents ...</v-btn>
          <v-dialog v-model="exp_textarea02" persistent max-width="400" content-class="j_modal type01">
            <v-card>
              <v-card-title class="modal__header">
                <span>
                  Note Contents
                  <small></small>
                </span>
                <j-button class="modal_close" @click.stop="exp_textarea02 = false">
                  <v-icon>mdi-close</v-icon>
                </j-button>
              </v-card-title>
              <v-card-text>
                <textarea
                  v-model="NoteContentsText"
                  class="type_exp"
                  placeholder="Spool Installation"
                ></textarea>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <j-button class="type01 sky" text @click="exp_textarea02 = false">Save</j-button>
                <j-button class="type01" text @click="exp_textarea02 = false">Cancel</j-button>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="NoteContentsFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="NoteContentsStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="NoteContentsSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="NoteContentsColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Date Picker
      </div>
      <v-flex class="control">
        <div class="type_common_select">
          <label>Cut off Date</label>
          <date-picker :cutOffDay="'Fri'" :pointColor="'#40afe4'" :type="'cutOff'" /> 
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import { ChartLibraryService } from "@/services"
import StaticOptions from '@/mixins/staticOptions'
import DatePicker from '../../floating/JDatePicker'

export default {
  name: 'j-chart-tab-note',
  mixins: [
    StaticOptions
  ],
  components: {
    DatePicker
  },
  data: () => ({
    chartLibraryService: null,

    collapsed: {
      note: true,
      text: false,
      contents: false,
    },
    //Fix Me 어떤 data인지 체크 필요 
    dataImport: [
      { text: 'option01', val: 'option01' },
      { text: 'option02', val: 'option02' },
      { text: 'option03', val: 'option03' }
    ],
    bar3d_general: null,
    exp_textarea01: false,
    exp_textarea02: false
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['note']),

    NoteDisplay: {
      get() { return this.note.NoteDisplay },
      set(val) { this.setChartItem({ NoteDisplay: val }) }
    },
    NoteX: {
      get() { return this.note.NoteX || 0 },
      set(val) { this.setChartItem({ NoteX: parseFloat(val || 0) }) }
    },
    NoteY: {
      get() { return this.note.NoteY || 0 },
      set(val) { this.setChartItem({ NoteY: parseFloat(val || 0) }) }
    },
    NoteAlign: {
      get() { return this.note.NoteAlign },
      set(val) { this.setChartItem({ NoteAlign: val }) }
    },
    NoteTitleText: {
      get() { return this.note.NoteTitleText },
      set(val) { this.setChartItem({ NoteTitleText: val }) }
    },
    NoteTitleFont: {
      get() { return this.note.NoteTitleFont },
      set(val) { this.setChartItem({ NoteTitleFont: val }) }
    },
    NoteTitleStyle: {
      get() { return this.note.NoteTitleStyle },
      set(val) { this.setChartItem({ NoteTitleStyle: val }) }
    },
    NoteTitleSize: {
      get() { return this.note.NoteTitleSize || 0 },
      set(val) { this.setChartItem({ NoteTitleSize: parseFloat(val || 0) }) }
    },
    NoteTitleColor: {
      get() { return this.note.NoteTitleColor },
      set(val) { this.setChartItem({ NoteTitleColor: val }) }
    },
    NoteContentsText: {
      get() { return this.note.NoteContentsText },
      set(val) { this.setChartItem({ NoteContentsText: val }) }
    },
    NoteContentsFont: {
      get() { return this.note.NoteContentsFont },
      set(val) { this.setChartItem({ NoteContentsFont: val }) }
    },
    NoteContentsStyle: {
      get() { return this.note.NoteContentsStyle },
      set(val) { this.setChartItem({ NoteContentsStyle: val }) }
    },
    NoteContentsSize: {
      get() { return this.note.NoteContentsSize || 0 },
      set(val) { this.setChartItem({ NoteContentsSize: parseFloat(val || 0) }) }
    },
    NoteContentsColor: {
      get() { return this.note.NoteContentsColor },
      set(val) { this.setChartItem({ NoteContentsColor: val }) }
    },
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
  }
}
</script>
